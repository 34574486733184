.addressContainer {
  background-color: black;
  color: white; 
  padding: 35px; 
  margin-top: auto;
  font-size:18px; 
  line-height: 20px; 
}

.officeHoursContainer {
    background-color: #f0f0f0;
    padding-top: 50px; 
    padding-bottom: 50px; 
    padding-left: 10%; 
    padding-right: 10%; 
}