.ourPastorContainer {
    padding-top: 60px; 
    padding-bottom: 60px; 
    padding-left: 10%; 
    padding-right: 10%; 
    flex: 50%;
    text-align: left;
    font-size: 17px;
}

.pastorRow {
    display: flex;
    width: inherit;
    height: inherit;
}

.pastorPic {
    margin-right: 50px; 
    object-fit: contain;
    border-radius: 15px;
    display: block;
    max-width: 35%;
}

.pastorPicMobile {
    display: none;
}

@media (max-width: 900px) { 
    .pastorPicMobile {
        display: block;
        object-fit: contain;
        border-radius: 10px;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        margin-top: 40px;
    }

    .pastorPic {
        display: none;
    }
}