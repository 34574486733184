.titleImageContainer {
    background-image: url("../assets/bible-placeholder.png");
    background-repeat:no-repeat;
    background-size: cover;
    background-position: center; 
    filter: grayscale(100%);
    background-blend-mode:lighten;
   
    padding-top: 125px; 
    padding-bottom: 125px; 
}

.coreBeliefsContainer {
    padding-top: 50px; 
    padding-bottom: 20px;
    margin-bottom: 100px; 
}

.mobileCoreBeliefsCard {
    display: none;
}

.carouselContainer {
    display: block;
    position: relative; 
    width: 100%; 
    margin-top: 60px;
    z-index: -1;
}

.coreBeliefsCard {
    width: 400px; 
    min-height: 40px; 
    padding: 20px; 
    text-align: center; 
    background: #D7BE69; 
    color: #FFF; 
    border-radius: 10px; 
    font-weight: 600; 
    justify-content: center; 
    display: flex; 
    align-items: center; 
}

.fullDoctrineContainer {
    padding: 25px; 
    padding-left: 10%; 
    padding-right: 10%; 
    background-color: #f3f3f3;
}

.fullDoctrineBody {
    text-align: left;
}

.fullDoctrineBody p {
    font-size: 14px;
}

.fullDoctrineBody h4 {
    font-size: 24px; 
}

.doctrineDisclaimer {
    margin-top: 50px;
    color: darkred
}

@media (max-width: 900px) { 
    .mobileCoreBeliefsCard {
        display: block;
        background-color: #D7BE69;
        margin-left: 10%;
        margin-right: 10%; 
        padding: 15px; 
        padding-left: 5%; 
        padding-right: 5%; 
        border-radius: 8px;
        color: white; 
        font-weight: 600;
        line-height: 30px;
        text-align: left;
        margin-top: 15px; 
        margin-bottom: 15px;
        font-size: 14px; 
    }

    .carouselContainer {
        display: none;
    }

    .coreBeliefsContainer {
        margin-bottom: 50px;
    }
}