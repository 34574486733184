.socialIconButton {
    margin-top: 25px; 
    border: none;
    margin-left: 10px; 
    margin-right: 10px; 
    font-size: 40px; 
    transition: all 0.7s;
    background-color:white;
    color: black; 
    padding-top: 20px; 
    padding-left: 20px; 
    padding-right: 20px; 
    padding-bottom: 10px;
    border-radius: 50px;
}

.socialIconButton:hover {
    transform: scale(1.1);
    overflow: hidden;
    color: #D7BE69;
}

.socialButtonContainer {
    margin-top: 5px; 
}

.infoText {
    font-size: 19px;
}

@media (max-width: 400px) {
    .infoText {
        font-size: 16px;
    }

    .socialButtonContainer {
        padding-left: 15%;
        padding-right: 15%;
    }
  }