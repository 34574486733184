@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap");

html,body{
    overflow-x: hidden;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    /* font-family: "Inter", sans-serif; */
    background-color: white;
  }
  
button:hover {
    cursor: pointer; 
}

a:hover {
    cursor: pointer; 
}

footer {
    background-color: black;
    color: white; 
    padding: 35px; 
    margin-top: auto;
    font-size:14px; 
    line-height: 15px; 
}

/* SPACERS */ 

.goldSpacer {
    background-color: #D7BE69;
    width: 100%;
    height: 7px;
}

.blackSpacer {
    background-color: black;
    width: 100%;
    height: 7px;
}

/* BUTTONS */ 

.primaryButton {
    background-color: #D7BE69;
    border-radius: 50px;
    color:white; 
    border: none; 
    padding: 15px;
    padding-left: 50px; 
    padding-right: 50px; 
    font-weight: 600;
    margin-top: 20px; 
    margin-left: 25px; 
    margin-right: 25px; 
    min-height: 20px;
    min-width: 210px;
    font-size:16px; 
    text-decoration: none;
    transition: all 0.7s;
}

.primaryButton:hover {
    background-color: goldenrod;
    padding-left: 52px; 
    padding-right: 52px;
    min-width: 214px; 
}

/* text */ 

.standardURL {
    text-decoration: underline;
    color: blue; 
}

/* containers */

.shortImageContainer {
    background-image: url("../assets/Main\ webpage\ short.JPG");
    background-repeat:no-repeat;
    background-size: cover;
    background-position: center; 
   
    padding-top: 125px; 
    padding-bottom: 125px; 
    color: white; 
}

