.mainImageContainer {
    background-image: url("../assets/Main\ webpage.JPG");
    background-repeat:no-repeat;
    background-size: cover;
    background-position: center; 
   
    height: calc(100vh - 85px);
    max-height: 1000px;
    justify-content: center;
    display: flex;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;
    color: white; 
}

.meetingsContainer {
    padding-top:60px; 
    padding-bottom: 80px; 
    padding-left: 10%;
    padding-right: 10%; 
}

.meetingDetailsContainer {
    background-color: #f3f3f3;
    padding-top:40px; 
    padding-bottom: 50px; 
    padding-left: 10%;
    padding-right: 10%; 
}

.meetingDetailsContainer:target {
    scroll-margin-top: 80px;
}

.childMeetingDetails {
    margin-top: 40px; 
    padding-top: 20px; 
    padding-bottom: 20px; 
    padding-left: 10%; 
    padding-right: 10%; 
    background-color: white;
    margin-bottom: 20px;
}

.childMeetingDetails:target {
    scroll-margin-top: 150px;
}

.contactContainer {
    padding-top: 50px; 
    padding-bottom: 50px; 
    padding-left: 10%; 
    padding-right: 10%; 
    background-color: rgb(224, 224, 224);
}

.annoucementContainer {
    padding-top: 50px; 
    padding-bottom: 50px; 
    padding-left: 10%; 
    padding-right: 10%; 
    /* background-color: rgb(234, 234, 234); */
    background-color: #f7f5ed;
    scroll-margin-top: 50px;
}

.annoucementContainer p {
    font-size: 18px; 
}

.announcementButton {
    background-color: #D7BE69;
    border-radius: 50px;
    color:white; 
    border: none; 
    padding: 15px;
    margin-top: 10px; 
    padding-left: 40px; 
    padding-right: 40px; 
    font-weight: 600;
    min-height: 20px;
    min-width: 210px;
    font-size:16px; 
    text-decoration: none;
    transition: all 0.7s;
}

.announcementButton:hover {
    background-color: goldenrod;
}

@media (max-width: 500px) { 
    .announcementButton {
        transform: scale(0.8);
    }
}