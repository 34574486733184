.fellowshipDescription {
    padding-left: 10%;
    padding-right: 10%;
    line-height: 30px; 
    padding-top:20px; 
    padding-bottom: 20px;
    background-color: #D7BE69;
    font-weight: 600;
    color: white; 
    margin-bottom: 20px ;
}