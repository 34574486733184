
.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  text-transform: uppercase;
}

.navbar {
  height: 85px;
  background-color: white;
  position: fixed; 
  top: 0; 
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
  z-index: 1;
}

.dropdownContent {
  background-color: white;
  padding:10px 20px 10px 20px; 
  font-size: 13px; 
  z-index: 1; 
  box-shadow: 0 0 2px 0 rgba(0,0,0,.6);
  border-radius: 5px;

  transform-origin: var(--radix-dropdown-menu-content-transform-origin);
  animation: stretchIn 0.5s;
}

.aboutDropdownContent {
  background-color: white;
  padding:15px 40px 15px 20px; 
  font-size: 13px; 
  z-index: 1; 
  line-height: 40px;
  text-transform: capitalize;
  text-decoration: none;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.3);

  transform-origin: var(--radix-dropdown-menu-content-transform-origin);
  animation: stretchIn 0.5s;
}

@keyframes stretchIn {
  from {
    opacity: 0;
    transform: scaleY(0.2);
  }
  to {
    opacity: 1;
    transform: scaleY(1);
  }
}

.aboutDropdownMobile {
  display: none;
}

.logo {
    font-weight: 900;
    font-size: 18px; 
}

.language-toggle {
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 7px 13px 7px 13px; 
  border-radius: 3px;
  border-style: none;
  font-size: 13px;
  box-shadow: 0 0 1px 0 rgba(0,0,0,.9);
  white-space:nowrap;
  overflow:hidden;
  color: black; 
}

.tcbc-title {
    text-decoration: none;
    color: black;
    justify-content: center;
    display: flex;
    align-items: center; 
}

.heart-img {
    width: 35px;
    padding-right: 10px;
}

.menu-icon {
  display: none;
}

.navbar-tab {
  transition: 0.75s;
  font-size: 14px;
  font-weight: 600;
  color: #2f234f;
  white-space:nowrap;
  overflow:hidden;
  transition: 0.5s; 
}

.navbar-tab:hover {
  color:darkred; 
}

.subnavbar-tab {
  transition: 0.75s;
  font-size: 14px;
  font-weight: 600;
  color: #2f234f;
  text-transform: uppercase;
  text-decoration: none;
  display: block;
}

.subnavbar-tab:hover {
  color:darkred; 
}

.nav-elements ul {
  display: flex;
  list-style-type: none;
  justify-content: center;
  align-items: center;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 40px;
}

.nav-elements ul a {
  font-size: 14px;
  font-weight: 600;
  color: #2f234f;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 900px) {
  .menu-icon {
    display: block;
    cursor: pointer;
    /* padding-left: 20px;  */
    /* height: 85px;  */
    margin-left: 20px; 
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 22px; 
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 85px;
    background-color: white; 
    width: 0px;
    height: calc(100vh - 85px);
    transition: all 0.4s ease-in;
    overflow: hidden;
    opacity: 85%;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }

  .aboutDropdownContent{
    display: none;
  }

  .aboutDropdown {
    display: none;
  }

  .aboutDropdownMobile {
    display: block;
  }
}

@media (max-width: 600px) {
  .heart-img {
    padding-right: 5px;
  }
}